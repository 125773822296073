import validate from "/home/admin/www/zaimki.pl/release/20241116162650/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.13_esl_lv5wulkpbkswkjq5vdqjtqgdqe/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45global from "/home/admin/www/zaimki.pl/release/20241116162650/middleware/redirect.global.ts";
import manifest_45route_45rule from "/home/admin/www/zaimki.pl/release/20241116162650/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.13_esl_lv5wulkpbkswkjq5vdqjtqgdqe/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "layout-from-query": () => import("/home/admin/www/zaimki.pl/release/20241116162650/middleware/layoutFromQuery.ts")
}