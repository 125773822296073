import { default as abuseReportscYjlkSyJi7Meta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93EoF4IuwgQoMeta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as indexjy7EDI2iX7Meta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/admin/index.vue?macro=true";
import { default as moderation5o9vEQluWdMeta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/admin/moderation.vue?macro=true";
import { default as pendingBansuZgZcGc4iCMeta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/admin/pendingBans.vue?macro=true";
import { default as profilesdoq7D1xQlVMeta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/admin/profiles.vue?macro=true";
import { default as expensesd3I8UTs9TKMeta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/admin/timesheets/expenses.vue?macro=true";
import { default as indexQtope8nlxBMeta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/admin/timesheets/index.vue?macro=true";
import { default as overviewN8TEecug2RMeta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingDSMx66XeziMeta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/admin/translations/awaiting.vue?macro=true";
import { default as missingUKhUPoVWHoMeta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/admin/translations/missing.vue?macro=true";
import { default as usersx2v5G3GR0BMeta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/admin/users.vue?macro=true";
import { default as apiAzoUUavOieMeta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/api.vue?macro=true";
import { default as _91slug_93dfDQ84Ddp4Meta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/blog/[slug].vue?macro=true";
import { default as indexupLiH4eon2Meta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_93AGgf6tubyvMeta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_93GkbSPEQD5yMeta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as adminS7etdcIE6fMeta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/census/admin.vue?macro=true";
import { default as indexw52oMPCWW2Meta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/census/index.vue?macro=true";
import { default as contactbnQWG5BmyEMeta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/contact.vue?macro=true";
import { default as designhi4bRjvmrBMeta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/design.vue?macro=true";
import { default as english37vyhfZFsjMeta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/english.vue?macro=true";
import { default as faqTcy6d7i2qbMeta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/faq.vue?macro=true";
import { default as inclusivePZQbUSzRlRMeta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/inclusive.vue?macro=true";
import { default as indexSdOlh4mEsMMeta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/index.vue?macro=true";
import { default as licenseWo64yyJeg6Meta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/license.vue?macro=true";
import { default as academicDZhtGfSK2hMeta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/links/academic.vue?macro=true";
import { default as indexLyZyKBIfrwMeta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/links/index.vue?macro=true";
import { default as mediadE6xFMZV1oMeta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/links/media.vue?macro=true";
import { default as translinguisticsUXgJvfZEVBMeta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/links/translinguistics.vue?macro=true";
import { default as zineaEQMeZjxmfMeta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/links/zine.vue?macro=true";
import { default as namesh29CZcOH0iMeta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/names.vue?macro=true";
import { default as indexhRKzCI18qHMeta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/nouns/index.vue?macro=true";
import { default as templates1zKzmjtsUsMeta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/nouns/templates.vue?macro=true";
import { default as peopleqyQq2DSrREMeta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/people.vue?macro=true";
import { default as privacyEpoqNXNej0Meta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/privacy.vue?macro=true";
import { default as _91username_93OlixzDGKhRMeta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_93BOB0ua3iGFMeta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/profile/card-[username].vue?macro=true";
import { default as editor0A4lJhmbZ5Meta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/profile/editor.vue?macro=true";
import { default as anyfWVoIv03nIMeta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/pronouns/any.vue?macro=true";
import { default as askOaSlsRP9u0Meta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/pronouns/ask.vue?macro=true";
import { default as avoidingIS6jpYquhnMeta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/pronouns/avoiding.vue?macro=true";
import { default as indexGM864uSwVvMeta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/pronouns/index.vue?macro=true";
import { default as mirrorj6TaiDdfZhMeta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/pronouns/mirror.vue?macro=true";
import { default as pronounrDliNI6cnEMeta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/pronouns/pronoun.vue?macro=true";
import { default as sources2ZSzwMQEEMMeta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/sources.vue?macro=true";
import { default as teamkDrjmCOnh8Meta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/team.vue?macro=true";
import { default as terminologyz9UlvzxfrmMeta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/terminology.vue?macro=true";
import { default as termsH3okK2ZAHsMeta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/terms.vue?macro=true";
import { default as user5QKq5IIZdQMeta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/user.vue?macro=true";
import { default as workshopsMMxRsLwnPbMeta } from "/home/admin/www/zaimki.pl/release/20241116162650/pages/workshops.vue?macro=true";
import { default as neutratywy1pA2aUI8UMMeta } from "~/data/nouns/neutratywy.vue?macro=true";
import { default as dukatywyefwx6B0yWaMeta } from "~/data/nouns/dukatywy.vue?macro=true";
import { default as osobatywyJt6U0sHeFZMeta } from "~/data/nouns/osobatywy.vue?macro=true";
import { default as iksatywy4G8TfyCT5SMeta } from "~/data/nouns/iksatywy.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReportscYjlkSyJi7Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBansuZgZcGc4iCMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/admin/profiles.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: apiAzoUUavOieMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93dfDQ84Ddp4Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexupLiH4eon2Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_93AGgf6tubyvMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_93GkbSPEQD5yMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: adminS7etdcIE6fMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: indexw52oMPCWW2Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactbnQWG5BmyEMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: english37vyhfZFsjMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqTcy6d7i2qbMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusivePZQbUSzRlRMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/inclusive.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/index.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academicDZhtGfSK2hMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: indexLyZyKBIfrwMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: mediadE6xFMZV1oMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguisticsUXgJvfZEVBMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zineaEQMeZjxmfMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: namesh29CZcOH0iMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: indexhRKzCI18qHMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templates1zKzmjtsUsMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: peopleqyQq2DSrREMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyEpoqNXNej0Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_93OlixzDGKhRMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_93BOB0ua3iGFMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editor0A4lJhmbZ5Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anyfWVoIv03nIMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-ask",
    path: "/pronouns/ask",
    meta: askOaSlsRP9u0Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/pronouns/ask.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoidingIS6jpYquhnMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: indexGM864uSwVvMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirrorj6TaiDdfZhMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronounrDliNI6cnEMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sources2ZSzwMQEEMMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: teamkDrjmCOnh8Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologyz9UlvzxfrmMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsH3okK2ZAHsMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: user5QKq5IIZdQMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshopsMMxRsLwnPbMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241116162650/pages/workshops.vue")
  },
  {
    name: "nouns-neutratywy",
    path: "/neutratywy",
    component: () => import("~/data/nouns/neutratywy.vue")
  },
  {
    name: "nouns-dukatywy",
    path: "/dukatywy",
    component: () => import("~/data/nouns/dukatywy.vue")
  },
  {
    name: "nouns-osobatywy",
    path: "/osobatywy",
    component: () => import("~/data/nouns/osobatywy.vue")
  },
  {
    name: "nouns-iksatywy",
    path: "/iksatywy",
    component: () => import("~/data/nouns/iksatywy.vue")
  }
]